import React from "react";

import Autoplay from "embla-carousel-autoplay";
import sales1 from "../../images/sales1.jpg";
import sales2 from "../../images/sales2.jpg";
import sales3 from "../../images/sales3.jpg";
import sales4 from "../../images/sales4.jpg";
import sales5 from "../../images/sales5.jpg";
import sales6 from "../../images/sales6.jpg";
import sales7 from "../../images/sales7.jpg";
import home1 from "../../images/home1.jpg";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./components/Carousel";

function Sales() {
  return (
    <div className="flex flex-col">
      <div
        className="relative bg-cover bg-no-repeat bg-top h-80"
        style={{ backgroundImage: `url(${home1})` }} // Replace with your actual image URL
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 flex items-center justify-center h-full">
          <h1 className="text-white text-7xl font-bold">Sales</h1>
        </div>
      </div>

      <Carousel
        plugins={[
          Autoplay({
            delay: 2000,
          }),
        ]}
        className="self-center w-11/12 sm:w-4/5 max-w-3xl  h-96 sm:px-0 mt-16"
      >
        <CarouselContent>
          <CarouselItem>
            <img src={sales1} alt="Sales-Image-1" className="h-96 w-full" />
          </CarouselItem>
          <CarouselItem>
            <img src={sales2} alt="Sales-Image-2" className="h-96 w-full" />
          </CarouselItem>
          <CarouselItem>
            <img src={sales3} alt="Sales-Image-3" className="h-96 w-full" />
          </CarouselItem>
          <CarouselItem>
            <img src={sales4} alt="Sales-Image-4" className="h-96 w-full" />
          </CarouselItem>
          <CarouselItem>
            <img src={sales5} alt="Sales-Image-5" className="h-96 w-full" />
          </CarouselItem>
          <CarouselItem>
            <img src={sales6} alt="Sales-Image-6" className="h-96 w-full" />
          </CarouselItem>
          <CarouselItem>
            <img src={sales7} alt="Sales-Image-7" className="h-96 w-full" />
          </CarouselItem>
        </CarouselContent>
        <CarouselPrevious className="hidden sm:flex items-center justify-center bg-gray-700" />
        <CarouselNext className="bg-gray-700 hidden sm:flex items-center justify-center" />
      </Carousel>

      <h1 className="text-black text-5xl self-center font-bold mt-10 mb-0">
        Supplies
      </h1>
      <h2 className="text-slate-600 mt-3 text-2xl self-center font-semibold">
        Data Loggers
      </h2>
      <p className="text-slate-900 max-w-5xl px-4 text-justify sm:px-0 mt-8 text-2xl self-center font-semibold">
        We supply advanced data loggers for the pharmaceutical industry,
        equipped with thermocouples, RTDs, and other high-accuracy sensors for
        precise monitoring of temperature, humidity, and pressure. These devices
        ensure compliance with regulatory standards, providing reliable data
        collection and real-time monitoring to safeguard sensitive
        pharmaceutical products.
      </p>
      <div className="px-6 pt-4 pb-2 my-8 self-center gap-x-0 flex flex-col sm:flex-row sm:gap-x-8 lg:gap-x-16">
        <span className="cursor-pointer inline-block bg-gray-200 rounded-full px-6 py-2 text-lg font-semibold text-gray-700 mr-2 mb-2 transition-colors duration-300 hover:bg-gray-300 hover:text-gray-900">
          Yokogawa data loggers
        </span>
        <span className=" cursor-pointer inline-block bg-gray-200 rounded-full px-6 py-2 text-lg font-semibold text-gray-700 mr-2 mb-2 transition-colors duration-300 hover:bg-gray-300 hover:text-gray-900">
          Wireless Data loggers
        </span>
        <span className=" cursor-pointer inline-block bg-gray-200 rounded-full px-6 py-2 text-lg font-semibold text-gray-700 mr-2 mb-2 transition-colors duration-300 hover:bg-gray-300 hover:text-gray-900">
          All types of Temperature sensors
        </span>
      </div>
    </div>
  );
}

export default Sales;
