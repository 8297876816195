import React from "react";
import excelImage from "../../images/excel.jog.jpg";
import "../css/excel.css";

const Excel = () => {
  return (
    <>
      <div className="excel-image flex justify-center w-full">
        <img src={excelImage} alt="CSV" />
      </div>
      <div className="excel1-text flex flex-col gap-y-8">
        <h1 className="my-4 font-semibold"> Excel Sheet</h1>
        <h4 className="mb-6 font-semibold">
          {" "}
          Excel Sheet Validation is a crucial process for ensuring the accuracy,
          reliability, and compliance of spreadsheet applications, <br></br>
          particularly in regulated industries such as pharmaceuticals,
          biotechnology, and medical devices. As Excel spreadsheets are often
          used for critical data calculations, reporting, and decision-making,
          validating these tools is essential to maintaining data integrity and
          meeting regulatory requirements.
        </h4>
      </div>
      <div className="excel2-text">
        <h2 className="my-4 font-semibold">
          {" "}
          Our Excel Sheet Validation services include:
        </h2>
        <h4 className="mb-6 font-semibold">
          Requirement Gathering: We work closely with you to define the
          functional requirements of your Excel spreadsheets, ensuring they meet
          all user needs and regulatory expectations.
        </h4>
      </div>
      <div className="excel3-text">
        <h2 className="my-4 font-semibold"> Risk Assessment:</h2>
        <h4 className="mb-6 font-semibold">
          We conduct a thorough risk assessment to identify potential risks
          associated with the use of Excel sheets, such as calculation errors,
          data entry issues, and unauthorized access.
        </h4>
      </div>
      <div className="excel4-text">
        <h2 className="my-4 font-semibold"> Validation Planning: </h2>
        <h4 className="mb-6 font-semibold">
          A comprehensive validation plan is developed, detailing the scope,
          approach, and testing strategies required to validate the Excel
          sheets.{" "}
        </h4>
      </div>
      <div className="excel5-text">
        <h2 className="my-4 font-semibold"> Testing: </h2>
        <h4 className="mb-6 font-semibold">
          {" "}
          We perform rigorous testing, including Unit Testing, Functional
          Testing, and User Acceptance Testing (UAT), to verify that the Excel
          sheets function as intended and produce accurate results.
        </h4>
      </div>
      <div className="excel6-text">
        <h2 className="my-4 mt-8 font-semibold"> Documentation: </h2>
        <h4 className="my-8 font-semibold">
          {" "}
          Detailed documentation is maintained throughout the validation
          process, providing evidence of compliance and a clear audit trail for
          regulatory inspections. Training and Support: We offer training to
          ensure that your team understands how to use the validated Excel
          sheets correctly and how to maintain their compliance over time.
        </h4>
      </div>
    </>
  );
};

export default Excel;
