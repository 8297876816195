import React, { useState } from "react";
import "./css/Navbar.css";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { Download } from "lucide-react";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showServicesMenu, setShowServicesMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleServicesHover = () => {
    setShowServicesMenu(true);
  };

  const handleServicesLeave = () => {
    setShowServicesMenu(false);
  };

  return (
    <div className="container z-50">
      <nav className="navbar">
        <div className="logo">
          <img src={logo} alt="Logo" />
          {/* <h2>THRIVE IT QUALIFYIT <br />Laboratory</h2> */}
        </div>
        <div className="menu-toggle" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <ul
          className={`menu ${showMenu ? "show" : ""} flex items-center gap-x-6`}
        >
          <li>
            <Link to="/" onClick={toggleMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={toggleMenu}>
              About
            </Link>
          </li>
          <li
            className="services-dropdown"
            onMouseEnter={handleServicesHover}
            onMouseLeave={handleServicesLeave}
          >
            <Link className="services-link" to="#">
              Services
            </Link>
            {showServicesMenu && (
              <div className="floating-submenu">
                <ul>
                  <li>
                    <Link to="/csv" onClick={toggleMenu}>
                      Computer System Validation
                    </Link>
                  </li>
                  <li>
                    <Link to="/CandQ" onClick={toggleMenu}>
                      Commissioning & Qualification
                    </Link>
                  </li>
                  <li>
                    <Link to="/Excel" onClick={toggleMenu}>
                      Excel Sheet Validation
                    </Link>
                  </li>
                  <li>
                    <Link to="/Trainings" onClick={toggleMenu}>
                      Trainings
                    </Link>
                  </li>
                  <li>
                    <Link to="/Sales" onClick={toggleMenu}>
                      Sales
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </li>
          <li>
            <Link to="/career" onClick={toggleMenu}>
              Career
            </Link>
          </li>
          <li>
            <Link to="/clint" onClick={toggleMenu}>
              Client
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={toggleMenu}>
              Contact
            </Link>
          </li>
          <li className="flex items-center">
            <a href="/files/brochure.pdf" download>
              <button
                type="button"
                className="text-white no-underline flex items-center justify-center border-b-0 bg-gray-800 hover:bg-gray-900 rounded-lg text-sm px-5 py-2.5 me-2 font-semibold"
              >
                <Download className="mr-2.5 h-5" />
                <span className="pt-0.5">Brochure</span>
              </button>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
