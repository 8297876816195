import React from "react";
import csvImage from "../../images/csv.jpg"; // Adjusted relative path
import csv2Image from "../../images/csv4.jpg";
import csv10Image from "../../images/csv7.jpg";
// import '../css/Services.css';
import "../css/csv.css";

const CSV = () => {
  return (
    <>
      <div className="csv5-image">
        <img src={csvImage} alt="CSV" />
      </div>
      <div className="csvp1">
        <h3>
          Validation of computer systems is essential to confirm their accuracy,
          reliability,consistent performance as intended, and the capability to
          identify invalid or modified records.This process is a fundamental
          requirement for compliance with electronic record standards. Our team
          of computer system validation specialists conducts validations for
          various sectors,including pharmaceutical and biologics manufacturers,
          medical device producers,clinical research organizations, and GLP
          laboratories. Regulatory bodies mandate the computer system validation
          (CSV) process to guarantee the accuracy and integrity of data within
          computerized systems, as well as to ensure product safety and
          efficacy. CSV should be performed when configuring a new system or
          when making updates to an existing validated system, such as upgrades,
          patches, or extensions. CSV processes must adhere to established
          regulations and guidelines. Best practices relevant to the field and
          the specific attributes of the system are subject to approval.{" "}
        </h3>
      </div>

      <div className="csv-container">
        <div className="csv10-image">
          <img src={csv10Image} alt="CSV" />
        </div>
        <div className="csv10-text">
          {/* <h1>Dedicated CSV Service Line</h1> */}
          <h2>Dedicated CSV Service Line</h2>
          <h4 className="flex flex-col gap-y-1 list-disc">
            <ul class="list-disc ml-6 space-y-1">
              <li>Industry SMEs guide Program Success</li>
              <li>Our SMEs continuously oversee proceedings</li>
              <li>Effective Governance meets desired objectives</li>
              <li>Skill Management across Client Locations</li>
              <li>Planning & People Management across Locations</li>
              <li>Minimal intervention from Client CSV Team</li>
              <li>Reduced TCO in the long run</li>
              <li>
                Business Continuity with flexibility for quick Ramp-up/down
              </li>
            </ul>
          </h4>
        </div>
      </div>
      <div className="csv-section ">
        <div className="csv-text">
          <h1>Our CSV services include:</h1>
          <p>
            <ul class="list-disc ml-6 space-y-1">
              <li>
                Requirement Analysis: We work with you to define clear and
                precise user and system requirements.
              </li>
              <li>
                Risk Management: We conduct thorough risk assessments to
                identify and mitigate potential risks.
              </li>
              <li>
                Validation Planning: A detailed validation plan is created to
                outline the scope and strategy.
              </li>
              <li>
                System Testing: We perform comprehensive testing procedures to
                ensure the system meets requirements.
              </li>
            </ul>
          </p>
        </div>
        <div className="csv4-image">
          <img src={csv2Image} alt="CSV" />
        </div>
      </div>

      <div className="csv-text1">
        <h1>COMPUTER SYSTEM VALIDATION</h1>
        <ul className="flex flex-col gap-y-1 ml-6 list-disc font-normal">
          <li>Upgradtion Of Legacy System</li>
          <li>Automation Support</li>
          <li>PLC and SCADA Validation as per GAMP Requirements</li>
          <li>
            Application Software Validation as per GAMP and EUANX 11 and CFR
            Part 11
          </li>
          <li>EMS/BMS Quatlification</li>
          <li>Audit Trail Review Assessment</li>
          <li>Periodic Review Assessments</li>

          <li>ERES Assessments</li>
          <li>GAP Assessments for ALL type Computerized System</li>
        </ul>
      </div>

      <div className="csv-system">
        <h1>System Testing</h1>
        <h3>
          <ul class="list-disc space-y-1 ml-6">
            <li>
              System Testing: We perform comprehensive testing procedures,
              including Installation Qualification (IQ), Operational
              Qualification (OQ), and Performance Qualification (PQ), to ensure
              the system meets all functional and regulatory requirements.
            </li>
            <li>
              Documentation: All steps of the validation process are
              meticulously documented, providing a clear audit trail and
              evidence of compliance.
            </li>
          </ul>
        </h3>
      </div>
    </>
  );
};

export default CSV;
