import React from "react";
// import '../components/css/Title.css';
import "./css/Title.css";

const Title = ({ title }) => {
  return (
    <div className="title font-semibold">
      <h2>{title} </h2>
    </div>
  );
};

export default Title;
